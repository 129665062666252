import LayoutPrivate from "../../Layouts/Layout.private";
import {Col, Modal, Row, Typography} from "antd";
import FaceBoy from "../../assets/images/FaceBoy.svg";
import Evaluation from "../Components/Private/Evaluation";
import {useContext, useEffect, useState} from "react";
import GlobalContex from "../../Store/GlobalContext";
import io from "socket.io-client";
import {API_BASE_URL} from "../../config/config";
import {postRequest} from "../../config/ApiService";
import FaceGirl from "../../assets/images/FAceGirl.svg";
import Face from "../../assets/images/undefined.png";
import useCheckRegister from "../../hooks/useCheckRegister";

const { Title, Paragraph } = Typography;

const socket = io(`${API_BASE_URL}`, { transports: ['websocket', 'polling'] });

function Admin() {

  const { user: { auth, setAuth }, test: { setOpen }, iframe: { setIframeResponse } } = useContext(GlobalContex);
  const [refresh, setRefresh] = useState<any>(null);
  const [modalImage, setModalImage] = useState<boolean>(false);
  const { checkUser } = useCheckRegister();

  useEffect(() => {
    if (auth?.useId) {
      socket.on(`candidate/${auth?.useCodeId}`, data => {
        if (data?.completed) {
          setOpen(false);
          setRefresh(new Date().toISOString());
          if (data?.uteId) {
            const dataSave = { testResult: { uteId: data?.uteId } };
            localStorage.setItem('review', JSON.stringify(dataSave));
            setIframeResponse(dataSave);
          }
        }
      });
      checkUser();
    }
    if (auth?.use_new && !localStorage.getItem('openImageModal')) {
      // setModalImage(true);
      // localStorage.setItem('openImageModal', 'true');
    }
  }, [auth]);

  const getServerPhoto = (fileName: string) => {
    postRequest(`/api/users/get_image?usePhoto=${fileName}`, {}, 'GET').then(
      (response: any) => {
        if (response?.status === 200) {
          setAuth({
            ...auth,
            usePhoto: `data:image/png;base64,${response?.data?.data?.photoB64}`
          });
        } else {
          setAuth({
            ...auth,
            usePhoto: null
          });
        }
      }
    ).catch(error => {
      setAuth({
        ...auth,
        usePhoto: null
      });
    })
  }

  const getPhoto = () => {
    if (auth?.usePhoto) {
      return auth.usePhoto;
    }
    if (auth.useGender === null || auth.useGender === "PNC") {
      return Face;
    }
    return (auth?.useGender === "F") ? FaceGirl : FaceBoy;
  }

  useEffect(() => {
    if (auth?.usePhoto && !auth?.usePhoto?.includes('data:image/')) {
      getServerPhoto(auth.usePhoto);
    }
  }, [auth]);

  return (
    <LayoutPrivate>
      <Row style={{ paddingTop: '30px' }}>
        <Col lg={10} style={{ paddingTop: '47px' }}>
          <Row justify="center">
            <Col span={23} lg={18}>
              <Row justify="center">
                <Col>
                  <div className="text-center">
                    <div className="center-center">
                      <div className="avatar avatar-profile">
                        <img className="w-100" src={ getPhoto() } alt="user"/>
                      </div>
                    </div>
                    <Title level={2} className="color-primary">{auth?.useFirstName} {auth?.useFirstSurname}</Title>
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: "3rem" }}>
                  <Title level={1} underline className="color-primary text-center">Bienvenid@ a ECO</Title>
                  <Paragraph className="text-justify line-height-small">
                    Esta es tu cuenta privada en la plataforma de Orientación Vocacional: Educational Career Orientation - ECO.
                    <br/>
                    <br/>
                    Crea tu perfil avanzado, visualiza tus metas y encuentra un plan que te ayude a realizarlas.
                    Analiza tus fortalezas y aptitudes para hacer match con la carrera profesional que mejor encaja contigo, for real!
                  </Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {
          (auth?.useId) && (
            <Col span={24} lg={14}>
              <Row justify="center">
                <Col span={23} lg={18}>
                  <Evaluation refresh={refresh} />
                </Col>
              </Row>
            </Col>
          )
        }
      </Row>

      <Modal centered={true} title={null} open={modalImage} footer={null} onCancel={() => setModalImage(false)}>
        <img style={{ width: '100%' }} src={`${API_BASE_URL}/assets/images/welcome.png`} alt="IMG"/>
      </Modal>
    </LayoutPrivate>
  );
}

export default Admin;
