import {Col, Grid, Row, Space, Typography} from "antd";
import Isotipo from "../../assets/images/isotipo.svg";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Linkedin from "../../assets/images/linkedin.svg";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const Year = new Date().getFullYear();

function FooterLayout() {

  const screens = useBreakpoint();

  return (
    <footer className="bg-primary w-100 " style={{ paddingTop: "1rem", paddingBottom: "1rem", marginTop: "10px" }}>
      <Row justify="center">
        <Col span={23} md={22} lg={20} xxl={18}>
          <Row gutter={[0, 20]} justify="space-between" align="middle">
            <Col span={24} lg={5} className={((screens.xs === true || screens.sm === true || screens.md === true) && screens.lg === false) ? "text-center" : ""}>
              <img height={40} src={Isotipo} alt="ECO"/>
            </Col>
            <Col span={24} lg={14}>
              <Row justify="space-between">
                <Col>
                  <Text>
                    <a className="color-secondary" href="https://arentto.com/estudiantes/" target="_blank">
                      { Year } yqsigo ®
                    </a>
                  </Text>
                </Col>
                <Col>
                  <Text>
                    <a className="color-secondary" href='https://plataformaeco.com/politica-privacidad/' target='_blank'>
                      Políticas de privacidad
                    </a>
                  </Text>
                </Col>
                <Col>
                  <Text>
                    <a className="color-secondary" href="mailto:eco@yqsigo.com">
                      Contacto
                    </a>
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={5} className={((screens.xs === true || screens.sm === true || screens.md === true) && screens.lg === false) ? "text-center" : "text-right"}>
              <Space>
                <a href="https://bit.ly/face-yqs" target="_blank">
                  <img height={15} src={Facebook} alt="Facebook"/>
                </a>
                <a href="https://bit.ly/insta-yqs" target="_blank">
                  <img height={15} src={Instagram} alt="Instagram"/>
                </a>
                <a href="https://bit.ly/in-yqs" target="_blank">
                  <img height={15} src={Linkedin} alt="Linkedin"/>
                </a>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
}

export default FooterLayout;
