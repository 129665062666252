import {Col, Row, Space, Typography} from "antd";

export interface TitleInterface {
  icon: any;
  title: string;
  subtitle: string;
  size: number;
  key: string;
  bgColor?: string;
  boxColor?: string;
  color?: string;
}

function TitleEvaluation({ title, subtitle, icon, size }: TitleInterface) {
  return (
    <Row align="middle" gutter={[10, 0]}>
      <Col>
        <img height={size} src={icon} alt=""/>
      </Col>
      <Col>
        <Space size={0} direction="vertical">
          <Typography.Text style={{ fontSize: "1rem" }} className="color-primary">
            { title }
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: "1.2rem" }} className="color-primary line-height-small">
            { subtitle }
          </Typography.Text>
        </Space>
      </Col>
    </Row>
  );
}

export default TitleEvaluation;
