import LayoutPrivate from "../../Layouts/Layout.private";
import {Col, Divider, message, Progress, Row, Spin, Tooltip, Typography} from "antd";
import parser from "html-react-parser";
import {useEffect, useState} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {postRequest} from "../../config/ApiService";
import {useNavigate} from "react-router-dom";
import {CareersResultInterface} from "../../interfaces/CareersResult.interface";
import HeaderSectionPage from "../Components/Private/HeaderSectionPage";
import SectionEvaluation from "../Components/Private/SectionEvaluation";

const { Title, Paragraph } = Typography;

function Careers() {

  const [careerData, setCareerData] = useState<CareersResultInterface | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCareers();
  }, []);

  const getCareers = () => {
    setLoading(true);
    postRequest(`/api/user_tests/results_careers`, { }, 'GET').then( //user_tests/results_careers
      ({data}) => {
        if (data?.success) {
          if (data?.data) {
            setCareerData(data.data);
          } else {
            message.info('No hay resultados para mostrar.');
          }
        } else {
          message.error('Error inesperado en el servidor.');
        }
      }
    ).catch(reason => {
      message.error('Error inesperado en el servidor.');
      navigate('/');
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <LayoutPrivate>
      <>
        {
          (loading) ? (
            <div style={{ height: '400px' }} className="center-center">
              <div className='text-center'>
                <Spin spinning={loading}/>
                <p>Estamos cargando tu información no cierres la página</p>
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: '5rem' }}>
              {
                (careerData) && (
                  <>
                    <HeaderSectionPage bannerConfig={careerData.banner} start='careers' />
                    {
                      (careerData?.results?.topRatedCareers?.length > 0) && (
                        <Row justify='center' style={{ paddingTop: '3rem', borderTop: '10px solid #EAEBF5' }}>
                          <Col span={23}>
                            <Row gutter={[20, 20]}>
                              {
                                careerData?.results.topRatedCareers?.map((carr: any, index: number) => (
                                  <Col key={index} span={24} md={8}>
                                    <div className="center-center">
                                      <Progress trailColor="#DCFFD7" strokeWidth={15} strokeColor="#60F85A" type="circle" percent={parseFloat(carr?.rca_score)} />
                                    </div>
                                    <div className='my-2 text-center'>
                                      <Title className="color-primary" level={4}>{ carr?.car_name }</Title>
                                      <Divider className='custom-divider m-0' />
                                    </div>
                                    <Paragraph className="color-primary text-justify line-height-small">
                                      { parser(carr?.car_description) }
                                    </Paragraph>
                                  </Col>
                                ))
                              }
                            </Row>

                            <Row gutter={[40, 40]} className='my-2'>
                              {
                                careerData.results.groupCareers?.map((group: any, index: number) => (
                                  <Col key={index} span={24} md={12} lg={8}>
                                    <div className='career-container-item'>
                                      <div className="point-career" />
                                      <Title level={5} className='color-primary'>
                                        { group?.cgr_name }
                                      </Title>
                                    </div>
                                    <Row>
                                      <Col span={24}>
                                        {
                                          group?.careers?.map((value: any, index1: number) => (
                                            <Row key={index1} gutter={[10, 10]}>
                                              <Col span={8}>
                                                <Paragraph className="m-b line-height-small">
                                                  { value?.car_name }
                                                </Paragraph>
                                              </Col>
                                              <Col flex='auto'>
                                                <Row gutter={[5, 0]} align='middle'>
                                                  <Col flex={"auto"}>
                                                    <Progress strokeWidth={15} percent={parseFloat(value?.rca_score)} strokeColor={'#34EBD5'} />
                                                  </Col>
                                                  <Col>
                                                    {
                                                      (value?.car_description) && (
                                                        <Tooltip color="#0B5580" placement="right" title={ value?.car_description }>
                                                          <InfoCircleOutlined />
                                                        </Tooltip>
                                                      )
                                                    }
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          ))
                                        }
                                      </Col>
                                    </Row>
                                  </Col>
                                ))
                              }
                            </Row>
                          </Col>
                        </Row>
                      )
                    }
                  </>
                )
              }
            </div>
          )
        }
      </>
    </LayoutPrivate>
  );
}

export default Careers;
