import {Col, Row, Grid} from "antd";
import LayoutPublic from "../../Layouts/Layout.public";
import LoginForm from "../Components/Public/LoginForm";
import {ConfigPages} from "./config.pages";
import {useState} from "react";
import {PublicInterface} from "../../interfaces/Public.interface";
import RegisterForm from "../Components/Public/RegisterForm";
import ConfirmRegister from "../Components/Public/ConfirmRegister";
import RecoveryForm from "../Components/Public/RecoveryForm";

const { useBreakpoint } = Grid;

function Home() {

  const [config, setConfig] = useState<PublicInterface>(ConfigPages.login);

  const { lg } = useBreakpoint();

  return (
    <LayoutPublic config={config}>
      <Row justify="center" align="middle" className={(lg) ? 'h-100' : ''}>
        <Col span={24} lg={16} className="p-1">
          {
            (config.id === "login") && <LoginForm setConfig={setConfig} />
          }
          {
            (config.id === "recovery") && <RecoveryForm setConfig={setConfig} />
          }
          {
            (config.id === "register") && <RegisterForm setConfig={setConfig} />
          }
          {
            (config.id === "confirm") && <ConfirmRegister setConfig={setConfig} />
          }
        </Col>
      </Row>
    </LayoutPublic>
  );
}

export default Home;
