import FaceBoy from "../../../assets/images/FaceBoy.svg";
import FaceGirl from "../../../assets/images/FAceGirl.svg";
import Face from "../../../assets/images/undefined.png";
import {Button, message, Modal, Spin, UploadProps} from "antd";
import {EditOutlined, InboxOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import GlobalContex from "../../../Store/GlobalContext";
import Dragger from "antd/es/upload/Dragger";
import {API_BASE_URL, AUTH_TOKEN} from "../../../config/config";
import {postRequest} from "../../../config/ApiService";

function AvatarEco() {

  const { user: { auth, setAuth } } = useContext(GlobalContex);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const props: UploadProps = {
    name: 'usePhoto',
    action: `${API_BASE_URL}/api/users/upload_photo`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${ localStorage.getItem(AUTH_TOKEN) }`,
    },
    showUploadList: false,
    onChange(info: any) {
      setLoading(true);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        getServerPhoto(info?.file?.response?.data);
        setVisible(false);
        setLoading(false);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setLoading(false);
      }
    },
  };

  const getServerPhoto = (fileName: string) => {
    postRequest(`/api/users/get_image?usePhoto=${fileName}`, {}, 'GET').then(
      (response: any) => {
        if (response?.status === 200) {
          setAuth({
            ...auth,
            usePhoto: `data:image/png;base64,${response?.data?.data?.photoB64}`
          });
        } else {
          setAuth({
            ...auth,
            usePhoto: null
          });
        }
      }
    ).catch(error => {
      setAuth({
        ...auth,
        usePhoto: null
      });
    })
  }

  const getPhoto = () => {
    if (auth?.usePhoto) {
      return auth.usePhoto;
    }
    if (auth.useGender === null || auth.useGender === "PNC") {
      return Face;
    }
    return (auth?.useGender === "F") ? FaceGirl : FaceBoy;
  }

  useEffect(() => {
    if (auth?.usePhoto && !auth?.usePhoto?.includes('data:image/')) {
      getServerPhoto(auth.usePhoto);
    }
  }, [auth]);

  return(
    <div className="center-center">
      <div className="avatar avatar-profile">
        <img className="w-100" src={ getPhoto() } alt="user"/>
      </div>
      <div className='btn-contianer'>
        <Button type="primary" onClick={() => setVisible(true)} className="btn-avatar" size="small" shape="circle" icon={<EditOutlined />}/>
      </div>
      <Modal
        title="Cambiar foto de perfil"
        centered
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Spin spinning={loading}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
          </Dragger>
        </Spin>
      </Modal>
    </div>
  );
}

export default AvatarEco;
