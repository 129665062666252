import {Col, Row, Typography} from "antd";
import ButtonSection from "./ButtonSection";
import EvaluationGraph from "./EvaluationGraph";
import {useState} from "react";
import {Result} from "../../../interfaces/ResultEvaluation.interface";
const { Title, Paragraph } = Typography;

function SectionEvaluation({ item }: { item: Result }){

  const [open, setOpen] = useState(true);

  const clickButtonAction = () => {
    const isPending = (!item?.competences && !item.profiles && !item.factors);
    if (!isPending && item.tesFree) {
      setOpen(!open);
    }
  }

  return(
    <Row className='mb-1'>
      <Col span={23}>
        <Row>
          <Col span={24} lg={7} xl={8} xxl={8} className='bg-gray vertical-center'>
            <Title className='m-0 color-primary pl-4' level={3}>
              { item.tesName }
            </Title>
          </Col>
          <Col span={24} md={12} lg={9} xl={10} xxl={12}>
            <Paragraph className='color-primary line-height-small p-1'>
              { item.tesDescription }
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8} xl={6} xxl={4} onClick={clickButtonAction}>
            <ButtonSection key={item.tesId} item={item} open={open}  />
          </Col>
        </Row>
      </Col>
      {
        (open && (item?.profiles || item?.competences)) && (
          <Col span={24}>
            <Row justify='center'>
              <Col span={23}>
                <EvaluationGraph key={1} data={item} />
              </Col>
            </Row>
          </Col>
        )
      }
    </Row>
  );
}

export default SectionEvaluation;
