import {createContext} from "react";

const GlobalContext = createContext<any>({
  user: {
    auth: null,
    setAuth: null,
  },
  navBar: {
    nav: null,
    setNav: null,
  },
  test: {
    open: null,
    setOpen: null,
  },
  career: {
    showMenu: null,
    setShowMenu: null,
  },
  option: {
    currentOption: null,
    setCurrentOption: null,
  },
  iframe: {
    iframeResponse: null,
    setIframeResponse: null,
  },
});

export default GlobalContext;
