import {Button, Col, Divider, Drawer, Grid, Row} from "antd";
import {MailOutlined, MenuOutlined, PoweroffOutlined, PullRequestOutlined} from "@ant-design/icons";
import Logo from "../../assets/images/logo-ECO-plataforma.png";
import User from "./User";
import NavBar from "./NavBar";
import {useContext, useEffect, useState} from "react";
import {Menu} from "./menu";
import {useNavigate} from "react-router-dom";
import GlobalContext from "../../Store/GlobalContext";

const { useBreakpoint } = Grid;

function HeaderLayout() {

  const [visible, setVisible] = useState(false);
  const [menuList, setMenuList] = useState<any>([]);
  const screens = useBreakpoint();
  const navigation = useNavigate();
  const { navBar: { nav }, career: { showMenu, setShowMenu }, user: { auth, setAuth }} = useContext(GlobalContext);

  const goRoute = (item: any) => {
    navigation(item.route);
  }

  const closeSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    setAuth({});
    setShowMenu(false);
    navigation('/');
  }

  useEffect(() => {
    if (auth) {
      if (auth.role?.rolIdentifier === "ADMIN_ROLE") {
        setMenuList(Menu.admin);
      } else {
        setMenuList(Menu.client)
      }
    }
  }, [auth]);

  return (
    <header>
      {
        (screens.lg) && (
          <div style={{ height: "20px" }} className="bg-success" />
        )
      }
      <div className="bg-gray py-1">
        <Row justify="center">
          <Col span={23} md={22} lg={20} xxl={18}>
            <Row justify="space-between" align="middle">
              <Col>
                <img className="z-100" src={Logo} height={80} alt="ECO"/>
              </Col>
              {
                (screens.lg) && (
                  <Col>
                    <Row gutter={[20, 0]} align="middle">
                      <Col>
                        <User dropdown={true} />
                      </Col>
                      <Divider type="vertical" style={{ height: "80px", borderLeft: "2px solid #0B5580" }} />
                      <Col>
                        <Button type="text" className="color-primary" icon={<MailOutlined />}/>
                      </Col>
                      <Divider type="vertical" style={{ height: "80px", borderLeft: "2px solid #0B5580" }} />
                    </Row>
                  </Col>
                )
              }
              {
                ((screens.xs === true || screens.sm === true || screens.md === true) && screens.lg === false) && (
                  <>
                    <Button type="primary" icon={<MenuOutlined />} onClick={()=> setVisible(true)}/>
                    <Drawer title="Menú principal modal" placement="right" onClose={() => setVisible(false)} visible={visible}>
                      <Row justify="center">
                        <Col>
                          <User />
                        </Col>
                        <Col span={24}>
                          {
                            menuList?.map((item: any, index: number) => (
                              <div key={index} className={`item-menu color-primary ${ (item.route === nav) ? "menu-active" : "" }`} onClick={() => goRoute(item)}>
                                { item.icon } { item.name }
                              </div>
                            ))
                          }
                          {
                            (showMenu && (auth?.role?.rolIdentifier !== "ADMIN_ROLE")) && (
                              <div key={45} className={`color-primary item-menu ${ ('/careers' === nav) ? "menu-active" : "" }`} onClick={() => goRoute({ route: '/careers' })}>
                                <PullRequestOutlined /> Tus carreras afines
                              </div>
                            )
                          }
                          <Divider />
                          <div className="item-menu color-danger" onClick={closeSession}>
                            <PoweroffOutlined /> Cerrar sesión
                          </div>
                        </Col>
                      </Row>
                    </Drawer>
                  </>
                )
              }
            </Row>
          </Col>
        </Row>
      </div>
      {
        (screens.lg) && (
          <div className="bg-primary" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Row justify="center">
              <Col span={24} md={22} lg={20} xxl={18}>
                <NavBar menuList={menuList} />
              </Col>
            </Row>
          </div>
        )
      }
    </header>
  );
}

export default HeaderLayout;
