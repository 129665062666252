import {Col, Row} from "antd";
import LayoutPublic from "../../Layouts/Layout.public";
import {ConfigPages} from "./config.pages";
import {useState} from "react";
import {PublicInterface} from "../../interfaces/Public.interface";
import PasswordResetForm from "../Components/Public/PasswordResetForm";

function PasswordReset() {

  const [config] = useState<PublicInterface>(ConfigPages.login);

  return (
    <LayoutPublic config={config}>
      <Row justify="center" align="middle" className="h-100">
        <Col span={24} lg={16} className="p-1">
          <PasswordResetForm />
        </Col>
      </Row>
    </LayoutPublic>
  );
}

export default PasswordReset;
