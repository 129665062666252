import React, {useState} from "react";
import {Button, Col, Form, Input, message, Row, Space, Typography} from "antd";
import Divider from "./Divider";

import {postRequest} from "../../../config/ApiService";
import {useNavigate, useParams} from "react-router-dom";

const { Text, Paragraph } = Typography;
const { Item } = Form;

function PasswordResetForm() {

  const { code } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    const dateSent = {
      ...values,
      preToken: code
    }
    postRequest(`/api/password_resets/recover`, dateSent).then(
      ({data}) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          navigate('/');
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  return (
    <Row justify="center">
      <Col span={24} sm={20} md={16} lg={20} xl={18}>
        <Paragraph className="color-primary line-height-small" style={{ fontSize: "2rem" }}>
          <b>Restablecer</b> contraseña
        </Paragraph>
        <Form form={form} onFinish={onFinish}>
          <Item
            name="useEmail"
            rules={[{ required: true, message: 'Por favor, ingrese un correo electrónico.' }]}
          >
            <Input size="large" placeholder="Correo electrónico" />
          </Item>
          <Item
            name="usePassword"
            rules={[{ required: true, message: 'Por favor, ingrese su contraseña.' }]}
          >
            <Input.Password size="large" placeholder="Nueva contraseña" />
          </Item>

          <Space direction="vertical" className='text-center w-100'>
            <Button shape='round' type='default' size="large" className="btn-wen" htmlType="submit" loading={loading}>
              Cambiar contraseña
            </Button>
          </Space>
        </Form>
      </Col>
      <Col span={24} sm={20} md={18} lg={22} xl={20} className="text-center pt-1">
        <Divider box={false} />
        <Space direction="vertical">
          <Text className="color-primary">¿Ya tienes cuenta en ECO?</Text>
          <Button type="default" className="btn-dark" shape="round" onClick={() => navigate("/")}>
            Iniciar sesión
          </Button>
        </Space>
      </Col>
    </Row>
  );
}

export default PasswordResetForm;
