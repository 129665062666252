import {AutoComplete, Col, Form, Row} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {OptionInterface} from "../../../interfaces/Option.interface";

function DynamicInput({ field, options, form }: { field: any, options: Array<any>, form: any }) {

  const [optionsList, setOptionList] = useState([]);

  const onSearch = (searchText: string) => {
    const filterOptions: any = [];
    options.forEach((option: OptionInterface) => {
      if (option?.optionName?.toLowerCase()?.includes(searchText.toLowerCase())) {
        filterOptions.push({
          value: option?.optionName
        });
      }
    });
    setOptionList(filterOptions);
  };

  return(
    <Row>
      <Col span={24}>
        <Form.Item
          name={field?.fieldName}
          rules={[
            {
              required: false,
              message: "Ingrese un nombre.",
            },
          ]}
          initialValue={field?.value}
        >
          <AutoComplete
            options={optionsList}
            onSearch={onSearch}
            placeholder="Nombre"
            className="w-100"
            defaultValue={field?.value}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default DynamicInput;
