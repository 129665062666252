import fetch from './interceptor';

// @ts-ignore
const postRequest = (url, data, method = 'post', formData = false) => {
  // @ts-ignore
  return fetch({
    url,
    method,
    data: data,
    formData
  })
}

// @ts-ignore
const get = (url) => {
  // @ts-ignore
  return fetch({
    url,
    method: "GET",
    data: null,
    formData: false
  });
}

// @ts-ignore
const downloadRequest = ({url}) => {
  // @ts-ignore
  return fetch({
    url,
    method: 'get',
    responseType: 'blob'
  })
}

export {
  postRequest,
  get,
  downloadRequest,
}