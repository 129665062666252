import LayoutPrivate from "../../Layouts/Layout.private";
import {Button, Col, Row, Typography} from "antd";
import {useState} from "react";
import Logo from "../../assets/images/logo.svg";
import {downloadRequest} from "../../config/ApiService";

const { Paragraph } = Typography;

function Dashboard() {

  const [loading,  setLoading] = useState(false);

  const download = () => {
    setLoading(true);
    const dateNow = new Date().toISOString().substring(0, 10);
    try {
      downloadRequest({ url: `/api/candidates/download_excel` }).then(
        (response: Blob | any) => {
          const dataType   = response.type;
          const binaryData = [];
          const filename   = `${dateNow}_candidatos.xlsx`;

          const test = new File([response.data], filename);

          binaryData.push(test);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      ).finally(() => {
        setLoading(false);
      })
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <LayoutPrivate>
      <Row justify="center" style={{ height: '600px' }}>
        <Col lg={10} className="text-center">
          <img className="z-100 my-5" src={Logo} height={100} alt="ECO"/>
          <Paragraph>
            A continuación podrás descargar la lista de usuarios en formato Excel.
          </Paragraph>
          <Button type="primary" onClick={download} loading={loading}>Descargar</Button>
        </Col>
      </Row>
    </LayoutPrivate>
  );
}

export default Dashboard;
