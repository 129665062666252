function Divider({ box, colorOne = "bg-dark-gray", colorTwo = "bg-primary" }: { box: boolean, colorOne?: string, colorTwo?: string }) {
  return (
    <div className='container-divider'>
      {
        (box) && (
          <div className={`small-box ${colorTwo}`} />
        )
      }
      <div className={`divider ${colorOne}`}/>
    </div>
  );
}

export default Divider;
