import LayoutPrivate from "../../Layouts/Layout.private";
import {useContext, useEffect, useState} from "react";
import {postRequest} from "../../config/ApiService";
import {message, Spin} from "antd";
import GlobalContex from "../../Store/GlobalContext";
import HeaderSectionPage from "../Components/Private/HeaderSectionPage";
import SectionEvaluation from "../Components/Private/SectionEvaluation";
import {ResultEvaluationInterface} from "../../interfaces/ResultEvaluation.interface";
import useCheckRegister from "../../hooks/useCheckRegister";
import {ConfigAdmin} from "../Components/Private/Config.Admin";

function Evaluation() {

  const [loading, setLoading] = useState(false);
  const { checkUser } = useCheckRegister();
  const [listEvaluation, setListEvaluation] = useState<ResultEvaluationInterface | null>(null);
  const { user: { auth }, iframe: { iframeResponse } } = useContext(GlobalContex);

  useEffect(() => {
    if (auth) {
      getEvaluations();
    }
  }, [auth]);

  useEffect(() => {
    if (iframeResponse?.testResult) {
      getEvaluations();
    }
  }, [iframeResponse]);

  useEffect(() => {
    checkUser();
  }, []);

  const getEvaluations = () => {
    setLoading(true);
    postRequest('/api/user_tests', {}, 'get').then(
      ({data}) => {
        if (data?.success) {
          const dataObject = data.data;
          const dataEvaluation: Array<any> = [];
          Object.keys(dataObject).forEach((key) => {
            if (key === 'completed') {
              localStorage.setItem('review', JSON.stringify(dataObject[key][0]));
            }
            dataEvaluation.push({
              id: key,
              title: ConfigAdmin[key],
              options: dataObject[key]
            });
          });
          setTimeout(() => {
            refreshResult();
          }, 500);
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  const refreshResult = async () => {
    const dataTest = JSON.parse(localStorage.getItem('review') as string);
    let url = '/api/user_tests/results';
    if (dataTest?.testResult?.uteId) {
      url = `${url}?uteId=${dataTest?.testResult?.uteId}`
    }
    postRequest(url, {}, 'GET').then(
      ({data}) => {
        if (data?.success) {
          setListEvaluation(data?.data);
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    }).finally(() => {
      setLoading(false);
    });
  }

  return(
    <LayoutPrivate>
      <>
        {
          (loading) ? (
            <div style={{ height: '400px' }} className="center-center">
              <div className='text-center'>
                <Spin spinning={loading}/>
                <p>Estamos procesando tus resultados no cierres la página</p>
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: '5rem' }}>
              {
                (listEvaluation) && (
                  <>
                    <HeaderSectionPage bannerConfig={listEvaluation.banner} />
                    {
                      listEvaluation.results.map((item, index: number) => (
                        <SectionEvaluation key={index} item={item} />
                      ))
                    }
                  </>
                )
              }
            </div>
          )
        }
      </>
    </LayoutPrivate>
  );
}

export default Evaluation;
