import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Typography} from "antd";
import Divider from "./Divider";
import {ConfigPages} from "../../Public/config.pages";
import moment from 'moment';
import {dataCities} from "../Dumis/register.data";
import {RangePickerProps} from "antd/es/date-picker";

const { Text, Paragraph } = Typography;
const { Item } = Form;
const { Option } = Select;
const yearsData = Array.from({length: 9}, (_, i ) => (i + 1) + 2021);

function RegisterForm({ setConfig }: { setConfig: React.SetStateAction<any> }) {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(true);
  const [cities, setCities] = useState<Array<string>>([]);

  useEffect(() => {
    form.setFieldValue('useTermsConditions', null);
    const citiesList = dataCities.sort();
    setCities(citiesList);
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);

    setTimeout(() => {
      sessionStorage.setItem("user-register", JSON.stringify(values));
      setConfig(ConfigPages.confirm);
      setLoading(false);
    }, 100);
  }

  const getValues = () => {
    if (sessionStorage.getItem("user-register")) {
      const data = JSON.parse(sessionStorage.getItem("user-register") as string);
      data.useBirthDate = moment(data.useBirthDate);
      return data;
    }
    return {};
  }

  const goLogin = () => {
    setConfig(ConfigPages.login);
    sessionStorage.clear();
  }

  const changeTerms = (e: any) => {
    setErrorForm(!e?.target?.checked)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const year = new Date().getFullYear();
    return current && current > moment(`01-01-${year - 10}`).endOf('day');
  };

  return (
    <Row justify="center">
      <Col span={24} xl={20}>
        <Paragraph className="color-primary pl-20-create line-height-small" style={{ fontSize: "2rem" }}>
          <b>Completa</b> tus datos
        </Paragraph>
        <Form form={form} onFinish={onFinish} initialValues={ getValues() } autoComplete="off">
          <Item
            name="canCourse"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Select size="large" placeholder="Curso">
              <Option value="Primero de Bachillerato">Primero de Bachillerato</Option>
              <Option value="Segundo de Bachillerato">Segundo de Bachillerato</Option>
              <Option value="Tercero de Bachillerato">Tercero de Bachillerato</Option>
            </Select>
          </Item>
          <Item
            name="canGraduationYear"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Select showSearch={true} size="large" placeholder="Año de graduación">
              {
                yearsData.map((year, index) => (<Option key={index} value={year}>{ year }</Option>))
              }
            </Select>
          </Item>
          <Divider box={true}/>
          <Item
            name="useFirstName"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Input size="large" placeholder="Nombre" />
          </Item>
          <Item
            name="useFirstSurname"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Input size="large" placeholder="Apellido" />
          </Item>
          <Item
            name="useBirthDate"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <DatePicker disabledDate={disabledDate} size="large" className="w-100" placeholder="Fecha de nacimiento" format={"YYYY-MM-DD"} />
          </Item>
          <Item
            name="canCity"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Select showSearch={true} size="large" placeholder="Ciudad">
              {
                cities.map(city => ( <Option value={city}>{ city }</Option> ))
              }
            </Select>
          </Item>
          <Item
            name="useEmail"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Input size="large" placeholder="Correo electrónico" />
          </Item>

          <div className="pl-20-create">
            <Item
              name="useGender"
              rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
              className="w-100"
            >
              <Radio.Group size="large" buttonStyle="solid" className="genre-buttons">
                <Radio.Button value="M" className="text-center no-selected-user">Masculino</Radio.Button>
                <Radio.Button value="F" className="text-center no-selected-user">Femenino</Radio.Button>
                <Radio.Button value="PNC" className="text-center no-selected-user">Prefiero no contestar</Radio.Button>
              </Radio.Group>
            </Item>
          </div>

          <Divider box={true}/>

          <Item
            name="usePassword"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Input.Password size="large" placeholder="Contraseña" />
          </Item>

          <Item
            name="useConfirmPassword"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
          >
            <Input.Password size="large" placeholder="Confirmar contraseña" />
          </Item>

          <Item
            name="useTermsConditions"
            rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
            className="pl-20-create"
            valuePropName="checked"
          >
            <Checkbox onChange={changeTerms}>
              He leído y acepto los <a href="https://plataformaeco.com/politica-privacidad/" target='_blank'><Text strong underline>términos y condiciones</Text></a> de uso.
            </Checkbox>
          </Item>

          <div className="pl-20-create">
            <Button shape='round' size="large" type='primary' htmlType="submit" loading={loading} disabled={errorForm}>Siguiente</Button>
          </div>

        </Form>
      </Col>
      <Col span={24} sm={20} md={18} lg={22} xl={20} className="text-center pt-1">
        <Divider box={false} colorOne="bg-primary-wen" />
        <Space direction="vertical">
          <Text className="color-primary">¿Ya tienes cuenta en ECO?</Text>
          <Button type="default" className="btn-wen" shape="round" onClick={goLogin}>
            Iniciar sesión
          </Button>
        </Space>
      </Col>
    </Row>
  );
}

export default RegisterForm;
