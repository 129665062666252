import {useContext} from "react";
import GlobalContext from "../../Store/GlobalContext";
import {useNavigate} from "react-router-dom";
import { PullRequestOutlined } from "@ant-design/icons";

function NavBar( { menuList }: { menuList: Array<any> }) {

  const navigation = useNavigate();
  const { navBar: { nav }, career: { showMenu }, user: { auth }} = useContext(GlobalContext);

  const goRoute = (item: any) => {
    navigation(item.route);
  }

  return (
    <nav className="container-navbar">
      {
        menuList.map((item, index) => (
          <div key={index} className={`item-menu ${ (item.route === nav) ? "menu-active" : "" }`} onClick={() => goRoute(item)}>
            { item.icon } { item.name }
          </div>
        ))
      }
      {
        (showMenu && (auth?.role?.rolIdentifier !== "ADMIN_ROLE")) && (
          <div key={45} className={`item-menu ${ (nav.includes('/careers')) ? "menu-active" : "" }`} onClick={() => goRoute({ route: '/careers' })}>
            <PullRequestOutlined /> Tus carreras afines
          </div>
        )
      }
    </nav>
  );
}

export default NavBar;
