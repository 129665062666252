import {Col, Row, Tooltip, Typography} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {useEffect, useState} from "react";

const { Text } = Typography;

function GraphLine({ data, color }: { data: any, color?: string }) {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    let totalAux = data?.rfaScore || data?.rcoScore || data?.rca_score || 0;
    setTotal(totalAux);
  }, [data]);
  return (
    <div className='mb-1'>
      <Row justify="space-between">
        <Col>
          <Text className="color-primary">
            { data?.factor?.facName ||  data?.competence?.comName || data?.car_name } { ' ' }
            {
              (data?.factor?.facDescription || data?.competence?.comDescription || data?.car_description) && (
                <Tooltip placement="right" color="#0B5580" title={ data?.factor?.facDescription || data?.competence?.comDescription || data?.car_description }>
                  <InfoCircleOutlined />
                </Tooltip>
              )
            }
          </Text>
        </Col>
        <Col>
          <Text className="color-primary" strong>
            { (data?.rfaScore) && `${data?.rfaScore}/10` }
            { (data?.rcoScore) && `${data?.rcoScore}/100` }
            { (data?.rca_score) && `${data?.rca_score}/100` }
          </Text>
        </Col>
      </Row>
      <div className="container-line-graph">
        <div className={`line-graph-color ${(color) ? color : 'bg-success'}`} style={{ width: `${total}%` }} />
      </div>
    </div>
  );
}

export default GraphLine;
