import {Button, Form, Input, message, Radio, Space} from "antd";
import  { DownloadOutlined } from "@ant-design/icons";
import React, {useState} from "react";
import {ItemTest} from "../../../interfaces/Evaluation.interface";
import {postRequest} from "../../../config/ApiService";
import {Result} from "../../../interfaces/ResultEvaluation.interface";

interface SolicitudeInterface {
  option: ItemTest | Result | any;
  dataConfig: any;
  setOpenModal: any;
  popHoverOption: any;
  setDisable: any;
}

function FormSolitude({option, dataConfig, setOpenModal, popHoverOption, setDisable}: SolicitudeInterface) {

  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {

    const dataSend = {
      ...values,
      test: {
        tesId: option?.tesId
      }
    }

    setLoading(true);
    postRequest('/api/request_forms', dataSend).then(
      ({data}) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          setOpenModal(false);
          setDisable(true);
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" autoComplete="off">
        <Form.Item
          label="Nombres y apellidos"
          name="rfoFullName"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Teléfono celular"
          name="rfoMobilePhone"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <Input maxLength={10} />
        </Form.Item>

        <Form.Item
          label="Opciones:"
          name="rfoAction"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {
                dataConfig?.options?.map((item: any, index: number) => (
                  <Radio key={index} value={item}>{ item }</Radio>
                ))
              }
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <a href={popHoverOption.link} target="_blank"><DownloadOutlined /> Ficha Informativa</a>
        </Form.Item>

        <div className="text-center">
          <Button htmlType="submit" type="primary" loading={loading}>Guardar</Button>
        </div>

      </Form>
    </div>
  );
}

export default FormSolitude;
