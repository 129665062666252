import {Button, Col, Divider, Dropdown, Menu, Row, Space, Typography} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import FaceBoy from "../../assets/images/FaceBoy.svg";
import {useContext} from "react";
import GlobalContex from "../../Store/GlobalContext";
import {Link, useNavigate} from "react-router-dom";
import FaceGirl from "../../assets/images/FAceGirl.svg";
import Face from "../../assets/images/undefined.png";

const { Text } = Typography;

function User({ dropdown }: { dropdown?: boolean }) {

  const { user: { auth, setAuth } } = useContext(GlobalContex);
  const navigation = useNavigate();

  const closeSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    setAuth({});
    navigation('/');
  }

  const menu = (
    <Menu className="bg-success"
          items={[
            {
              key: '1',
              label: (
                <Link to='/profile'>
                  Mi perfil
                </Link>
              ),
            },
            {
              type: 'divider',
            },
            {
              key: '2',
              label: (
                <a onClick={closeSession}>
                  Cerrar sesión
                </a>
              ),
            }
          ]}
    />
  );

  const getPhoto = () => {
    if (auth?.usePhoto) {
      return auth.usePhoto;
    }
    if (auth.useGender === null || auth.useGender === "PNC") {
      return Face;
    }
    return (auth?.useGender === "F") ? FaceGirl : FaceBoy;
  }

  return (
    <Row gutter={[10, 0]} align="middle">
      <Col>
        <div className="container-avatar">
          <img className="w-100" src={ getPhoto() } alt="user"/>
        </div>
      </Col>
      <Col>
        <Space direction="vertical" size={0}>
          <Text strong className="color-primary">Bienvenid@ {auth?.useFirstName}</Text>
          <Text className="color-primary">
            <Button onClick={closeSession} type="link" style={{ padding: "0" }} className="color-primary"><b>Cerrar sesión</b>
            </Button>
          </Text>
        </Space>
      </Col>
      {
        (!dropdown) && (
          <Col span={24}>
            <Divider />
          </Col>
        )
      }
      {
        (dropdown) && (
          <Dropdown overlay={menu} placement="bottomLeft">
            <Col style={{height: "80px;"}} className="center-center">
              <CaretDownOutlined className="color-primary" />
            </Col>
          </Dropdown>
        )
      }
    </Row>
  );
}

export default User;
