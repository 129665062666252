import LayoutPrivate from "../../Layouts/Layout.private";
import {Card, Col, DatePicker, Form, Input, message, Row, Select, Spin, Typography} from "antd";
import { HighlightOutlined } from "@ant-design/icons";
import React, {useContext, useEffect, useState} from "react";
import GlobalContex from "../../Store/GlobalContext";
import AcademicForm from "../Components/Private/AcademicForm";
import AvatarEco from "../Components/Private/AvatarEco";
import {postRequest} from "../../config/ApiService";
import {RangePickerProps} from "antd/es/date-picker";
import moment from "moment";
import {dataCities} from "../Components/Dumis/register.data";
import {OptionInterface} from "../../interfaces/Option.interface";
import useCheckRegister from "../../hooks/useCheckRegister";
const { Item } = Form;

const { Title } = Typography;
const { Option } = Select;

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  const year = new Date().getFullYear();
  return current && current > moment(`01-01-${year - 10}`).endOf('day');
};

function Profile() {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);

  const { user: { auth, setAuth } } = useContext(GlobalContex);
  const { checkUser } = useCheckRegister();
  const [cities, setCities] = useState<Array<string>>([]);

  // config to careers
  const [optionsUniInternational, setUniInternational] = useState<Array<OptionInterface>>([]);
  const [optionsUniNational, setUniNational] = useState<Array<OptionInterface>>([]);
  const [optionsUniCareers, setUniCareers] = useState<Array<OptionInterface>>([]);

  const [ careersFields, setCareersFields ] = useState<Array<any>>([]);
  const [ nationalsFields, setNationalsFields ] = useState<Array<any>>([]);
  const [ internationalsFields, setInternationalsFields ] = useState<Array<any>>([]);
  const [ showError, setShowError ] = useState<boolean>(false);

  const configCareers = {
    optionsUniInternational,
    setUniInternational,
    optionsUniNational,
    setUniNational,
    optionsUniCareers,
    setUniCareers,
    careersFields,
    setCareersFields,
    nationalsFields,
    setNationalsFields,
    internationalsFields,
    setInternationalsFields,
    showError,
  };

  useEffect(() => {
    const citiesList = dataCities.sort();
    setCities(citiesList);
  }, []);

  useEffect(() => {
    if (auth) {
      form.setFieldsValue({
        useFirstName: auth?.useFirstName,
        useFirstSurname: auth?.useFirstSurname,
        useEmail: auth?.useEmail,
        useBirthDate: (auth?.useBirthDate) ? moment(auth.useBirthDate, 'YYYY-MM-DD') : null,
        canCity: auth?.candidate?.canCity,
        canSchool: auth?.candidate?.canSchool,
        canCourse: auth?.candidate?.canCourse,
        canGraduationYear: auth?.candidate?.canGraduationYear,
        canDescription: auth?.candidate?.canDescription,
        useMobileNumber: auth?.useMobileNumber,
      });
      setLoading(false);
    }
  }, [auth]);

  // Save Careers
  const onFinish = (values: any) => {

    setLoading(true);
    setShowError(false);

    const preferredCareer: Array<any> = [];
    const preferredUniversity: Array<any> = [];
    let save = true;

    // candidatosData
    const candidateData = Object.keys(values).filter((name) => (name.includes("can")));
    const updateData: any = {};
    candidateData?.forEach(value => {
      // @ts-ignore
      updateData[value] = values[value];
    });
    const dataSendUser = JSON.parse(JSON.stringify(values));

    // Carreras
    const careers = Object.keys(values).filter((name) => (name.includes("career_")));
    careers?.forEach((name, index) => {
      const register = careersFields?.find((value: any) => (value?.value === values[name]));
      if (register && register?.value) {
        preferredCareer.push({
          universityCareer: {
            ucaId: register.id,
            ucaName: register.value
          }
        });
      } else {
        if (values[name]) {
          preferredCareer.push({
            universityCareer: {
              ucaId: null,
              ucaName: values[name]
            }
          });
        }
      }
    });

    const nationals = Object.keys(values).filter((name) => (name.includes("uni_national_")));
    nationals?.forEach((name, index) => {
      const register = nationalsFields?.find((value: any) => (value?.value === values[name]));
      if (register && register?.value) {
        preferredUniversity.push({
          university: {
            uniId: register.id,
            uniName: register.value,
            uniNational: true
          }
        });
      } else {
        if (values[name]) {
          preferredUniversity.push({
            university: {
              uniId: null,
              uniName: values[name],
              uniNational: true
            }
          });
        }
      }
    });
    const internationals = Object.keys(values).filter((name) => (name.includes("uni_international_")));
    internationals?.forEach((name, index) => {
      const register = internationalsFields?.find((value: any) => (value?.value === values[name]));
      if (register && register?.value) {
        preferredUniversity.push({
          university: {
            uniId: register.id,
            uniName: register.value,
            uniNational: false
          }
        });
      } else {
        if (values[name]) {
          preferredUniversity.push({
            university: {
              uniId: null,
              uniName: values[name],
              uniNational: false
            }
          });
        }
      }
    });

    const existNational = preferredUniversity.find(value => (value.university.uniNational));
    if (!existNational) {
      setShowError(true);
      setLoading(false);
      return;
    }
    let dataSend = {
      candidate: {
        canId: auth.candidate.canId
      },
      preferredUniversity,
      preferredCareer,
    }

    // guardamos los datos del usuario.
    updateCandidate(updateData, dataSendUser);

    postRequest(`/api/candidates`, dataSend, 'PUT').then(
      ({data}) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    }).finally(() => {
      setLoading(false);
    });
  }

  const updateCandidate = (candidate: any, userDate: any) => {
    const copyUser = JSON.parse(JSON.stringify(auth));
    delete copyUser.usePassword;
    delete copyUser.usePhoto;
    let dataSend = {
      ...copyUser,
      ...userDate,
      candidate: {
        ...copyUser.candidate,
        ...candidate
      }
    }

    delete dataSend.canCity;
    delete dataSend.canCourse;
    delete dataSend.canDescription;
    delete dataSend.canGraduationYear;
    delete dataSend.canSchool;
    delete dataSend.career_1;
    delete dataSend.career_2;
    delete dataSend.career_3;
    delete dataSend.career_4;
    delete dataSend.uni_international_1;
    delete dataSend.uni_international_2;
    delete dataSend.uni_international_3;
    delete dataSend.uni_international_4;
    delete dataSend.uni_national_1;
    delete dataSend.uni_national_2;
    delete dataSend.uni_national_3;
    delete dataSend.uni_national_4;

    if (!Boolean(dataSend?.usePassword)) {
      delete dataSend.usePassword;
    }


    postRequest(`/api/users`, dataSend, 'PUT').then(
      ({data}) => {
        if (data?.success) {
          const newDataUser = {...dataSend, usePhoto: auth?.usePhoto, usePassword: ''};
          setAuth(newDataUser);
        }
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    }).finally(() => {
      setLoading(false);
      checkUser();
      form.setFieldValue('usePassword', '');
    });
  }

  return (
    <LayoutPrivate>
      <Spin spinning={loading}>
        <Form form={form} autoComplete="off" onFinish={onFinish}>
          <Row justify='space-evenly' style={{ marginBottom: '3rem' }}>
            <Col span={24}>
              <Row>
                <Col span={24} lg={8} className='bg-gray' style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                  <Title level={3} className='color-primary' style={{ marginBottom: '7px', paddingLeft: '2rem' }}>
                    Configuración de la cuenta
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={10}>
              <Row justify="center">
                <Col span={23} md={20}>
                  <Row justify="center">
                    <Col>
                      <div className="text-center">
                        <AvatarEco />
                        <Title level={2} className="color-primary line-height-small m-0">Foto de perfil</Title>
                        <p className="m-0 color-primary">Sube la foto de perfil que te gustaria usar.</p>
                      </div>
                    </Col>
                    <Col span={24} style={{ marginTop: "3rem" }}>
                      <Title level={5} className="color-primary pl-2">Información Personal</Title>
                      <Card style={{ padding: "0px" }}>
                        <Item
                          label='Nombres'
                          name="useFirstName"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <Input size="large" suffix={<HighlightOutlined className='color-primary' />} />
                        </Item>
                        <Item
                          label='Apellidos'
                          name="useFirstSurname"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <Input size="large" suffix={<HighlightOutlined className='color-primary' />} />
                        </Item>
                        <Item
                          label='Fecha de nacimiento'
                          name="useBirthDate"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <DatePicker disabledDate={disabledDate} size="large" suffixIcon={<HighlightOutlined className='color-primary' />} className="w-100" format={"YYYY-MM-DD"} />
                        </Item>
                        <Item
                          label='Ciudad'
                          name="canCity"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <Select suffixIcon={<HighlightOutlined className='color-primary' />} showSearch={true} size="large" placeholder="Ciudad">
                            {
                              cities.map(city => ( <Option value={city}>{ city }</Option> ))
                            }
                          </Select>
                        </Item>
                        <Item
                          label='Número celular'
                          name="useMobileNumber"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <Input size="large" suffix={<HighlightOutlined className='color-primary' />} />
                        </Item>
                      </Card>
                      <Title level={5} className="color-primary mt-1 pl-2">Datos de la Cuenta</Title>
                      <Card style={{ padding: "0px" }}>
                        <Item
                          label='Correo electrónico'
                          name="useEmail"
                          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
                        >
                          <Input size="large" suffix={<HighlightOutlined className='color-primary' />} />
                        </Item>
                        <Item
                          label='Contraseña'
                          name="usePassword"
                        >
                          <Input.Password size="large" placeholder='*******' suffix={<HighlightOutlined className='color-primary' />} />
                        </Item>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={14}>
              <Row justify="center">
                <Col span={23} md={20}>
                  <AcademicForm form={form} configCareers={configCareers} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </LayoutPrivate>
  );
}

export default Profile;
