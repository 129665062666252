import {Col, message, Modal, Row, Spin} from "antd";
import TitleEvaluation from "./TitleEvaluation";
import {ConfigAdmin} from "./Config.Admin";
import ItemEvaluation from "./ItemEvaluation";
import {useContext, useEffect, useState} from "react";
import {postRequest} from "../../../config/ApiService";
import {ItemTest} from "../../../interfaces/Evaluation.interface";
import {useNavigate} from "react-router-dom";
import GlobalContex from "../../../Store/GlobalContext";

function Evaluation({ refresh }: { refresh: any }) {

  const [loading, setLoading] = useState(false);
  const [evaluationList, setEvaluationList] = useState<Array<any>>([]);
  const navigation = useNavigate();
  const { iframe: { iframeResponse } } = useContext(GlobalContex);

  useEffect(() => {
    if (iframeResponse?.testResult) {
      getEvaluations();
    }
  }, [iframeResponse]);

  useEffect(() => {
    getEvaluations();
  }, []);

  const getEvaluations = () => {
    setLoading(true);
    postRequest('/api/user_tests', {}, 'get').then(
      ({data}) => {
        if (data?.success) {
          const dataObject = data.data;
          const dataEvaluation: Array<any> = [];
          Object.keys(dataObject).forEach((key) => {
            if (key === 'completed') {
              localStorage.setItem('review', JSON.stringify(dataObject[key][0]));
            }
            dataEvaluation.push({
              id: key,
              title: ConfigAdmin[key],
              options: dataObject[key]
            });
          });
          setEvaluationList(dataEvaluation);
          if (refresh) {
            message.info('Test completado exitosamente.');
            navigation('/evaluations');
          }
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    }).finally(() => setLoading(false));
  }

  return (
    <>
      {
        (loading) ? (
          <div style={{ height: '400px' }} className="center-center">
            <div className='text-center'>
              <Spin spinning={loading}/>
              <p>Estamos procesando la información</p>
            </div>
          </div>
        ) : (
          <Row>
            {
              evaluationList.map((item, index) => (
                <Col key={index} span={24}>
                  <TitleEvaluation {...item.title} />
                  <div className="w-100 mb-2">
                    {
                      item?.options?.map((value: ItemTest, key: number) => (
                        <div key={key}>
                          <ItemEvaluation option={value} title={item.title} />
                        </div>
                      ))
                    }
                  </div>
                </Col>
              ))
            }
          </Row>
        )
      }
    </>
  );
}

export default Evaluation;
