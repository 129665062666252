import IconoOne from "../../../assets/images/icon_1.svg";
import IconoTwo from "../../../assets/images/icon_2.svg";
import IconoThree from "../../../assets/images/icon_3.svg";

export const ConfigAdmin: any = {
  pending: {
    key: "pending",
    icon: IconoOne,
    title: "Evaluaciones por",
    subtitle: "Completar",
    size: 30,
    bgColor: '#E3FCF9',
    boxColor: '#63ECDB',
    color: '#AFF7EE',
    popHovers: {
      2: {
        htmlText: `
              <b>Test de personalidad:</b> <br>
              Conoce en profundidad tu carácter para <br> asegurar que tu personalidad encaja  con <br> la carrera que  tienes en mente.
              
              <br> ¿Cuáles son los rasgos que  influyen en tu <br> comportamiento  y en  tu toma de decisión <br> profesional? <br>
            `,
        textLink: 'Más Información',
        link: 'https://www.centraltest.es/sites/default/files/document/pdf/perfil-pro-2-es.pdf'
      },
      3: {
        htmlText: `
              <b>Test de Intereses Vocacionales:</b> <br> ¿A qué le prestas atención y le dedicas tiempo sin darte cuenta? <br>
El test RIASEC evalúa y combina 6 intereses profesionales: <br> Realista, Investigador, Artístico, Social, <br> Emprendedor y Convencional. <br>
`,
        link: 'https://www.centraltest.es/sites/default/files/document/pdf/ficha-vocation2020ES.pdf',
        textLink: 'Más Información',
      }
    },
    options: [
      'Deseo que me contacten para más información',
      'Deseo incluir este test a mi perfil',
      'Tengo otra pregunta diferente',
    ]
  },
  completed: {
    key: "completed",
    icon: IconoTwo,
    title: "Evaluaciones",
    subtitle: "Completadas",
    size: 30,
    bgColor: '#E0FEDF',
    boxColor: '#60F85A',
    color: '#A6FBA2',
  },
  consultancies: {
    key: "Asesoramiento",
    icon: IconoThree,
    title: "Asesoramiento",
    subtitle: "Perzonalizado",
    size: 20,
    bgColor: '#CEDDE6',
    boxColor: '#0B5580',
    color: '#0B5580',
    popHovers: {
      4: {
        htmlText: `
              <p class="text-justify max-200">
                <p><b>Programa de Orientación Vocacional:</b></p>¡Combina tus intereses con tus rasgos de personalidad <br> para hacer match con la carrera que te hará  feliz y con <br> la que te harás un espacio en el mundo laboral! <br>
                Llevamos más de 15  años en la Orientación Vocacional <br> y hemos elaborado un  método propio con el que hemos <br> ayudado a miles de jóvenes.
              </p>
        `,
        link: 'https://arentto.com/estudiantes/',
        textLink: 'Más Información',
      }
    },
    options: [
      'Deseo que me contacten para más información',
      'Deseo tomar asesoría personalizada por medio del Programa',
      'Tengo otra pregunta diferente',
    ]
  },
}
