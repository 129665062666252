export const ConfigPages = {
  login: {
    id: "login",
    classColor: "bg-secondary",
    imageName: "girl",
    texts: [
      {
        title: "Descubre",
        text: "Todo tu potencial por medio de varias herramientas y análisis en una misma plataforma."
      },
      {
        title: "Visualiza",
        text: "Tus oportunidades a futuro reales en el contexto académico y profesional que encajan contigo."
      },
      {
        title: "Establece",
        text: "Un plan de acción para llegar a tus metas basado en información confiable y veraz."
      },
    ],
  },
  recovery: {
    id: "recovery",
    classColor: "bg-secondary",
    imageName: "boy",
    texts: [
      {
        title: "Descubre",
        text: "Todo tu potencial por medio de varias herramientas y análisis en una misma plataforma."
      },
      {
        title: "Visualiza",
        text: "Tus oportunidades a futuro reales en el contexto académico y profesional que encajan contigo."
      },
      {
        title: "Establece",
        text: "Un plan de acción para llegar a tus metas basado en información confiable y veraz."
      },
    ],
  },
  register: {
    id: "register",
    classColor: "bg-success",
    imageName: "boy",
    texts: [
      {
        title: "Descubre",
        text: "Todo tu potencial por medio de varias herramientas y análisis en una misma plataforma."
      },
      {
        title: "Visualiza",
        text: "Tus oportunidades a futuro reales en el contexto académico y profesional que encajan contigo."
      },
      {
        title: "Establece",
        text: "Un plan de acción para llegar a tus metas basado en información confiable y veraz."
      },
    ],
  },
  confirm: {
    id: "confirm",
    classColor: "bg-success",
    imageName: "boy",
    texts: [
      {
        title: "Descubre",
        text: "Todo tu potencial por medio de varias herramientas y análisis en una misma plataforma."
      },
      {
        title: "Visualiza",
        text: "Tus oportunidades a futuro reales en el contexto académico y profesional que encajan contigo."
      },
      {
        title: "Establece",
        text: "Un plan de acción para llegar a tus metas basado en información confiable y veraz."
      },
    ],
  },
}
