import {Button, Col, message, Modal, Popover, Row, Space} from "antd";
import {useContext, useState} from "react";
import GlobalContex from "../../../Store/GlobalContext";
import {ItemTest} from "../../../interfaces/Evaluation.interface";
import parser from "html-react-parser";
import FormSolitude from "./FormSolitude";

function ModalIframe({ option, dataConfig }: { option: ItemTest, dataConfig: any }) {

  // @ts-ignore
  const popHoverOption: any = dataConfig?.popHovers[option.tesId || option?.conId];

  const { test: { setOpen }, option: { setCurrentOption } } = useContext(GlobalContex);
  const [openModal, setOpenModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [disable, setDisable] = useState(false);

  const askForInformation = () => {
    if (option?.requestForm === null && !disable) {
      setOpenModal(true);
    }
  }

  const openIframe = () => {
    setCurrentOption(option);
    setOpen(true);
  }

  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };

  return (
    <>
      {
        (option?.tesFree)
          ? (
            <Button type={ (!option?.userTest) ? 'default' : 'primary' } shape={"round"} className={(!option?.userTest) ? 'btn-turquesa' : ''} disabled={!option?.userTest} onClick={openIframe}>
              Iniciar
            </Button>
          )
          : (
            <Popover
              style={{ width: 300 }}
              content={<>
                {
                  parser(popHoverOption?.htmlText || '')
                }

                <p style={{ marginTop: "20px" }}>
                  <a onClick={askForInformation}>
                    { popHoverOption?.textLink }
                  </a>
                </p>

              </>}
              title={null}
              trigger="hover"
              open={hovered}
              onOpenChange={handleHoverChange}
            >
              <Button type="primary" shape={"round"} disabled={option?.requestForm || disable} onClick={askForInformation} >
                {(option?.requestForm || disable) ? 'Solicitado' : 'Solicitar'}
              </Button>
            </Popover>
          )
      }
      <Modal
        title={ option?.tesName }
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <FormSolitude setDisable={setDisable} option={option} dataConfig={dataConfig} popHoverOption={popHoverOption} setOpenModal={setOpenModal}/>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ModalIframe;
