import {ReactNode, useContext, useEffect} from "react";
import {Col, Grid, Row, Typography} from "antd";
import Logo from "../assets/images/logo.svg";
import Girl from "../assets/images/chica_sombra.png";
import Boy from "../assets/images/chico_sombra.png";
import {PublicInterface} from "../interfaces/Public.interface";
import GlobalContext from "../Store/GlobalContext";
import {useNavigate} from "react-router-dom";

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;
const Year = new Date().getFullYear();

function LayoutPublic({ children, config }: { children: ReactNode, config: PublicInterface }) {

  const screens = useBreakpoint();
  const { user: { auth }, career: { setShowMenu } } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.useId) {
      if (auth?.role?.rolIdentifier !== "ADMIN_ROLE") {
        setShowMenu(false);
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    }
  }, [auth]);

  return (
    <Row className="min-vh-100">
      {
        (screens.lg) ? (
          <Col className="container-sections-auths" span={24} lg={12}>
            <div className="container-gray">
              <img className="logo" src={Logo} alt="ECO"/>
            </div>
            <div className={`container-color ${config.classColor}`} >
              <div className="container-inner-color">
                <div className="container-image">
                  {
                    (config.imageName === "girl") && <img className="img-girl-boy" src={Girl} alt="boy"/>
                  }
                  {
                    (config.imageName === "boy") && <img className="img-girl-boy" src={Boy} alt="boy"/>
                  }
                </div>
                <div className="container-texts">
                  <Row gutter={[30, 20]}>
                    {
                      config.texts.map((item, index) => (
                        <Col key={index} span={ (config.texts.length > 1) ? 8 : 24 } className={(index !== 0) ? 'border-left' : ''}>
                          {
                            (item?.title)
                            && (
                              <Title level={3} className="text-center">
                                <span className="color-primary">{ item.title }</span>
                              </Title>
                            )
                          }
                          <Paragraph className="text-justify color-primary line-height-small">{ item.text }</Paragraph>
                        </Col>
                      ))
                    }
                  </Row>
                </div>
                <div className="container-arentto text-center">
                  <Text className="color-primary">
                    <a href="https://arentto.com/estudiantes/" target="_blank">
                      { Year } yqsigo ®
                    </a>
                  </Text>
                </div>
              </div>
            </div>
          </Col>
        ) : (
          <Col span={24} lg={12}>
            <div className="container-gray-sm">
              <img className="logo" src={Logo} alt="ECO"/>
            </div>
          </Col>
        )
      }
      <Col span={24} lg={12}>
        <div style={{ overflowY: 'scroll', height: '100vh' }}>
          { children }
        </div>
      </Col>
    </Row>
  );
}

export default LayoutPublic;
