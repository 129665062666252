import {Col, Row, Typography} from "antd";
import {Banner} from "../../../interfaces/ResultEvaluation.interface";
import {useEffect, useState} from "react";
import {CareersResultInterface} from "../../../interfaces/CareersResult.interface";
import parser from "html-react-parser";

const { Title, Paragraph, Text } = Typography;

function HeaderSectionPage({ bannerConfig, start = 'evaluation' }: { bannerConfig: Banner, start?: string }) {
  const [description, setDescription] = useState<string>('');
  useEffect(() => {
    currentDescription();
  }, []);
  const currentDescription = () => {
    bannerConfig.tests.forEach((item) => {
      if (item?.testCompleted) {
        const textDescription = (start === 'evaluation') ? item?.tesIntroResult || '': item?.tesIntroCareers || '' ;
        setDescription(textDescription);
      }
    });

  }
  return(
    <Row>
      <Col span={24} className='bg-primary py-2'>
        <Row justify='center'>
          <Col span={23} md={20}>
            <Title level={3} className='color-white'>Progreso de tu perfil</Title>
            <Row className='bg-white mb-1'>
              {
                bannerConfig.tests.map((item) => (
                  <Col key={item.tesId} span={24} md={8} className='center-center py-1' style={{ backgroundColor: (item.testCompleted) ? '#C9FDC7': 'white' }}>
                    <Text strong className='color-primary'>{ item.tesSmallName }</Text>
                  </Col>
                ))
              }
            </Row>
            <Paragraph className='line-height-small color-white m-0'>
              {
                (description) ? description : <div>
                  <p className='line-height-small color-white m-0'>
                    0% completado <br/> Mira el avance de tu perfil de acuerdo a los test que tomes
                  </p>
                </div>
              }
            </Paragraph>
          </Col>
        </Row>
      </Col>
      {
        (bannerConfig?.title && bannerConfig?.description) && (
          <Col span={24} className='bg-white py-2'>
            <Row justify='center'>
              <Col span={23} md={20}>
                <Title level={3} className='color-primary'>
                  { bannerConfig?.title }
                </Title>
                <Paragraph className='line-height-small color-primary'>
                  { parser(bannerConfig?.description) }
                </Paragraph>
              </Col>
            </Row>
          </Col>
        )
      }
    </Row>
  );
}

export default HeaderSectionPage;

