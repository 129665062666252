import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Home from "./Pages/Public/Home";
import Admin from "./Pages/Private/Admin";
import GlobalContex from "./Store/GlobalContext";
import Auth from "./Store/Auth";
import NavBar from "./Store/NavBar";
import Profile from "./Pages/Private/Profile";
import PasswordReset from "./Pages/Public/PasswordReset";
import Evaluation from "./Pages/Private/Evaluation";
import Dashboard from "./Pages/Private/Dashboard";
import OpenModalTest from "./Store/OpenModalTest";
import Careers from "./Pages/Private/Careers";
import ShowMenuCareer from "./Store/ShowMenuCareer";
import {useState} from "react";

function EcoApp() {

  const { auth, setAuth } = Auth();
  const { nav, setNav } = NavBar();
  const { open, setOpen } = OpenModalTest();
  const { showMenu, setShowMenu } = ShowMenuCareer();
  const [ currentOption, setCurrentOption ] = useState<any>({});
  const [ iframeResponse, setIframeResponse ] = useState<any>({});

  const contextInit = {
    user: {
      auth, setAuth
    },
    navBar: {
      nav, setNav
    },
    test: {
      open, setOpen
    },
    career: {
      showMenu, setShowMenu
    },
    option: {
      currentOption, setCurrentOption
    },
    iframe: {
      iframeResponse, setIframeResponse
    },
  }

  return (
    <GlobalContex.Provider value={contextInit}>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/password_resets/:code" element={<PasswordReset />} />
          <Route path="/admin" element={ (auth) ? <Admin/> : <Navigate replace to="/" />} />
          <Route path="/dashboard" element={(auth) ? <Dashboard/> : <Navigate replace to="/" />} />
          <Route path="/profile" element={(auth) ? <Profile/> : <Navigate replace to="/" />} />
          <Route path="/evaluations" element={(auth) ? <Evaluation/> : <Navigate replace to="/" />} />
          <Route path="/careers" element={(auth) ? <Careers/> : <Navigate replace to="/" />} />
        </Routes>
      </Router>
    </GlobalContex.Provider>
  );
}

export default EcoApp;
