import {Col, Modal, Row, Space, Typography} from "antd";
import {CaretDownOutlined, CaretRightOutlined} from "@ant-design/icons";
import {Result} from "../../../interfaces/ResultEvaluation.interface";
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../../Store/GlobalContext";
import FormSolitude from "./FormSolitude";
import {ConfigAdmin} from "./Config.Admin";

function ButtonSection({ item, open }: { item: Result, open: boolean }) {

  const [pending, setPending] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const { test: {setOpen}, option: {setCurrentOption} } = useContext(GlobalContext);
  const [disable, setDisable] = useState(false);
  const [dataConfig, setDataConfig] = useState({});
  const [popHoverOption, setPopHoverOption] = useState({});

  useEffect(() => {
    const isPending = (!item?.competences && !item.profiles && !item.factors);
    setPending(isPending);
  }, [item]);

  const openModalFunction = () => {
    setCurrentOption(item);
    setOpen(true);
  }

  const openFormModal = () => {
    let dataItem: any = {}
    if (item?.tesId === 2) {
      dataItem = ConfigAdmin.pending;
    }
    if (item?.tesId === 3) {
      dataItem = ConfigAdmin.consultancies;
    }
    if (!Boolean(dataItem)) {
      return;
    }
    setDataConfig(dataItem);
    setPopHoverOption(dataItem.popHovers);
    setOpenModal(true);
  }

  return(
    <div>
      {
        (pending) ? (
          <>
            {
              (item.tesFree) ? (
                <div className='btn-section-one btn-section horizontal-center' style={{ backgroundColor: '#B8F8F0' }} onClick={openModalFunction}>
                  <div className='h-100 vertical-center d-flex-row'>
                    <div>
                      <Typography.Title level={4} className='color-primary m-0'>Pendiente</Typography.Title>
                    </div>
                    <div className='btn-circle center-center' style={{ backgroundColor: '#60F85A', fontSize: '20px', color: 'white', marginLeft: '10px' }}>
                      <CaretRightOutlined />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {
                    (item?.requestForm || disable) ? (
                      <div style={{ backgroundColor: '#C9FDC7' }} className='btn-section vertical-center text-center w-100'>
                        <Typography.Title level={4} className='color-primary m-0 w-100'>Solicitado</Typography.Title>
                      </div>
                    ) : (
                      <div className='btn-section-one btn-section horizontal-center' style={{ backgroundColor: '#B8F8F0' }} onClick={openFormModal}>
                        <div className='h-100 vertical-center d-flex-row'>
                          <div>
                            <Typography.Title level={4} className='color-primary m-0'>Solicitar</Typography.Title>
                          </div>
                          <div className='btn-circle center-center' style={{ backgroundColor: '#60F85A', fontSize: '20px', color: '#B8F8F0', marginLeft: '10px' }}>
                            <CaretRightOutlined />
                          </div>
                        </div>
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        ) : (
          <div className='btn-section-one'>
            <div style={{ backgroundColor: '#C9FDC7', marginRight: '4px', fontSize: '18px' }} className='center-center btn-section'>
              {
                (open) ? (
                  <CaretDownOutlined className='color-primary' />
                ) : (
                  <CaretRightOutlined className='color-primary' />
                )
              }
            </div>
            <div style={{ backgroundColor: '#C9FDC7' }} className='btn-section vertical-center pr-4 w-100'>
              <Typography.Title level={4} className='color-primary m-0'>Finalizado</Typography.Title>
            </div>
          </div>
        )
      }
      <Modal
        title={ item?.tesName }
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <FormSolitude setDisable={setDisable} option={item} dataConfig={dataConfig} popHoverOption={popHoverOption} setOpenModal={setOpenModal}/>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default ButtonSection;
