import React, {useState, useContext} from "react";
import {Button, Col, Form, Input, message, Row, Space, Typography} from "antd";
import Divider from "./Divider";
import { useNavigate } from "react-router-dom";

import {ConfigPages} from "../../Public/config.pages";
import {postRequest} from "../../../config/ApiService";
import {AUTH_TOKEN} from "../../../config/config";
import GlobalContex from "../../../Store/GlobalContext";

const { Text } = Typography;
const { Item } = Form;

function LoginForm({ setConfig }: { setConfig: React.SetStateAction<any> }) {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user: { setAuth } } = useContext(GlobalContex);

  const onFinish = (values: any) => {
    setLoading(true);
    postRequest('/api/auth/login', values).then(
      ({data}) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          setAuth({...data?.data?.user, usePassword: "******"} || {});
          localStorage.setItem(AUTH_TOKEN, data?.data?.access_token);
          if (data?.data?.user?.role?.rolIdentifier !== "ADMIN_ROLE") {
            navigate('/admin');
          } else {
            navigate('/dashboard');
          }
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  return (
    <Row justify="center">
      <Col span={24} sm={20} md={16} lg={20} xl={18}>
        <Text className="color-primary" style={{ fontSize: "2rem" }}>
          <b>Iniciar</b> sesión
        </Text>
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <Item
            name="useEmail"
            rules={[{ required: true, message: 'Por favor, ingrese un correo electrónico.' }]}
          >
            <Input size="large" placeholder="Correo electrónico:" />
          </Item>
          <Item
            name="usePassword"
            rules={[{ required: true, message: 'Por favor, ingrese su contraseña.' }]}
          >
            <Input.Password size="large" placeholder="Contraseña:" />
          </Item>
          <Item className='text-center'>
            <Text className="color-primary">
              ¿Olvidaste tu contraseña?
              <Button type="link" onClick={() => setConfig(ConfigPages.recovery)}>
                <Text strong className="color-primary" underline>Recupérala</Text>
              </Button>
            </Text>
          </Item>
          <Space direction="vertical" className='text-center w-100'>
            <Button shape='round' type='default' size="large" className="btn-wen" htmlType="submit" loading={loading}>
              Continuar
            </Button>
            <Text className="color-primary">
              Al continuar, aceptas las Condiciones de uso y el Aviso de privacidad de ECO
            </Text>
          </Space>
        </Form>
      </Col>
      <Col span={24} sm={20} md={18} lg={22} xl={20} className="text-center pt-1">
        <Divider box={false} />
        <Space direction="vertical">
          <Text strong className="color-primary">¿Eres nuevo?</Text>
          <Button type="default" shape="round" className="btn-dark" onClick={() => setConfig(ConfigPages.register)}>
            Crea tu cuenta en ECO
          </Button>
        </Space>
      </Col>
    </Row>
  );
}

export default LoginForm;
