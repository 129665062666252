import {Button, Col, Form, FormInstance, Input, message, Row, Select, Typography} from "antd";
import React, { useEffect} from "react";
import {postRequest} from "../../../config/ApiService";
import {OptionInterface} from "../../../interfaces/Option.interface";
import {AcademicInterface} from "../../../interfaces/Academic.interface";
import DynamicInput from "./DynamicInput";

const { Title, Text } = Typography;
const { Item } = Form;
const { Option } = Select;
const yearsData = Array.from({length: 9}, (_, i ) => (i + 1) + 2021);

function AcademicForm({ form, configCareers }: { form: FormInstance<any>, configCareers: any }) {

  const {
    optionsUniInternational,
    setUniInternational,
    optionsUniNational,
    setUniNational,
    optionsUniCareers,
    setUniCareers,
    careersFields,
    setCareersFields,
    nationalsFields,
    setNationalsFields,
    internationalsFields,
    setInternationalsFields,
    showError,
  } = configCareers;

  useEffect(() => {
    getCandidates();
    getInternationalUniversities();
    getNationalUniversities();
    getUniversityCareers();
  }, []);

  const getCandidates = () => {
    postRequest(`/api/candidates`, {}, 'GET').then(
      ({data}: { data: AcademicInterface}) => {
        if (data?.success) {
          if (data?.data?.preferredCareers) {
            const careers: any[] = [];
            data?.data?.preferredCareers?.forEach((value, index) => {
              if (value?.universityCareer?.ucaName) {
                careers.push({
                  id: value?.universityCareer?.ucaId,
                  value: value?.universityCareer?.ucaName,
                  fieldName: `career_${index + 1}`
                });
              }
            });
            if (careers.length < 3) {
              for (let i = (careers.length === 0 ? 1 : careers.length + 1); i <= 3; i++) {
                careers.push({
                  id: i,
                  value: "",
                  fieldName: `career_${i}`
                });
              }
            }
            setCareersFields(careers);
          }
          if (data?.data?.preferredUniversities) {
            const nationals: any[] = [];
            const internationals: any[] = [];

            const dataNational = data?.data?.preferredUniversities?.filter(value => (value?.university?.uniNational === true));
            const dataInterNational = data?.data?.preferredUniversities?.filter(value => (value?.university?.uniNational === false));

            dataNational?.forEach((value, index) => {
              nationals.push({
                id: value?.university?.uniId,
                value: value?.university?.uniName,
                fieldName: `uni_national_${index + 1}`
              });
            });

            dataInterNational?.forEach((value, index) => {
              internationals.push({
                id: value?.university?.uniId,
                value: value?.university?.uniName,
                fieldName: `uni_international_${index + 1}`
              });
            });
            console.log('nationals: ', nationals);
            if (nationals.length < 3) {
              for (let i = (nationals.length === 0 ? 1 : nationals.length + 1); i <= 3; i++) {
                nationals.push({
                  id: i,
                  value: "",
                  fieldName: `uni_national_${i}`
                });
              }
            }

            if (internationals.length < 3) {
              for (let i = (internationals.length === 0 ? 1 : internationals.length + 1); i <= 3; i++) {
                internationals.push({
                  id: i,
                  value: "",
                  fieldName: `uni_international_${i}`
                });
              }
            }

            setNationalsFields(nationals);
            setInternationalsFields(internationals);
          }
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    );
  }

  // Lista de datos a consumir
  const getInternationalUniversities = () => {
    postRequest(`/api/universities`, { uniNational: false }, 'POST').then(
      ({data}) => {
        setUniInternational(data?.data || []);
        const list: Array<OptionInterface> = [];
        data?.data?.forEach((item: any) => {
          list.push({
            optionId: item?.uniId,
            optionName: item?.uniName,
          })
        });
        setUniInternational(list);
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    });
  }

  const getNationalUniversities = () => {
    postRequest(`/api/universities`, { uniNational: true }, 'POST').then(
      ({data}) => {
        setUniNational(data?.data || []);
        const list: Array<OptionInterface> = [];
        data?.data?.forEach((item: any) => {
          list.push({
            optionId: item?.uniId,
            optionName: item?.uniName,
          })
        });
        setUniNational(list);
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    });
  }

  const getUniversityCareers = () => {
    postRequest(`/api/university-careers`, {}, 'POST').then(
      ({data}) => {
        const list: Array<OptionInterface> = [];
        data?.data?.forEach((item: any) => {
          list.push({
            optionId: item?.ucaId,
            optionName: item?.ucaName,
          })
        });
        setUniCareers(list);
      }
    ).catch(reason => {
      message.error(reason?.message || "Error inesperado en la solicitud.");
    });
  }

  return(
    <Row>
      <Col span={24}>
        <Title className="color-primary" level={3}>Información Académica </Title>

        <Text className='color-primary'><span className='color-danger'>*</span> Colegio:</Text>
        <Item
          label=''
          name="canSchool"
          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
        >
          <Input size="large" placeholder="" />
        </Item>

        <Text className='color-primary'><span className='color-danger'>*</span> Curso:</Text>
        <Item
          label=''
          name="canCourse"
          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
        >
          <Select size="large" placeholder="Seleccione">
            <Option value="Primero de Bachillerato">Primero de Bachillerato</Option>
            <Option value="Segundo de Bachillerato">Segundo de Bachillerato</Option>
            <Option value="Tercero de Bachillerato">Tercero de Bachillerato</Option>
          </Select>
        </Item>

        <Text className='color-primary'><span className='color-danger'>*</span> Año de graduación:</Text>
        <Item
          label=''
          name="canGraduationYear"
          rules={[{ required: true, message: 'Este campo es obligatorio.' }]}
        >
          <Select showSearch={true} size="large" placeholder="Seleccione">
            {
              yearsData.map((year, index) => (<Option key={index} value={year}>{ year }</Option>))
            }
          </Select>
        </Item>

        <Title className="color-primary" level={3}>Mi Perfil </Title>

        <Text className="color-primary">Descripción</Text>
        <Item
          name="canDescription"
        >
          <Input.TextArea rows={3} size="large" placeholder="Cuéntanos más de ti y tu proyección a futuro" />
        </Item>

        <Title className="color-primary" level={3}>Universidades de preferencia </Title>

        <Row gutter={[20, 0]}>
          <Col span={24} md={12}>
            <Text className="color-primary"><span className="color-danger" >*</span> Nacionales</Text>
            {
              nationalsFields?.map((field: any, index: number) => (
                <DynamicInput field={field} form={form} key={index} options={optionsUniNational} />
              ))
            }
            {
              (showError) && (
                <p className='m-0'>
                  <small className='color-danger'>Ingresa al menos una universidad nacional.</small>
                </p>
              )
            }
          </Col>
          <Col span={24} md={12}>
            <Text className="color-primary">Internacionales</Text>
            {
              internationalsFields?.map((field: any, index: number) => (
                <DynamicInput field={field} form={form} key={index} options={optionsUniInternational} />
              ))
            }
          </Col>

          <Col span={24} md={12}>
            <Title className="color-primary" level={3}>
              Carreras de preferencia
            </Title>
            {
              careersFields?.map((field: any, index: number) => (
                <DynamicInput field={field} form={form} key={index} options={optionsUniCareers} />
              ))
            }
          </Col>
          <Col span={24} md={12} className='center-center'>
            <Button type="primary" shape="round" size="large" htmlType="submit">
              Actualizar
            </Button>
          </Col>
        </Row>

      </Col>
    </Row>
  );
}

export default AcademicForm;
