import {useContext} from "react";
import GlobalContext from "../Store/GlobalContext";
import {postRequest} from "../config/ApiService";

function useCheckRegister() {

  const { career: { setShowMenu }} = useContext(GlobalContext);

  const checkUser = () => {
    postRequest('/api/candidates/check_register', { }, 'GET').then(
      ({data}) => {
        if (data?.success) {
          if (data?.data?.completedInformation) {
            setShowMenu(true);
          } else {
            setShowMenu(false);
          }
        } else {
          setShowMenu(false);
        }
      }
    ).catch(reason => {
      setShowMenu(false);
    })
  }

  return { checkUser };
}

export default useCheckRegister;
