import React, {useContext, useEffect, useState} from "react";
import {Button, Col, message, Row, Space, Typography} from "antd";
import Divider from "./Divider";
import {postRequest} from "../../../config/ApiService";
import {ConfigPages} from "../../Public/config.pages";
import {AUTH_TOKEN} from "../../../config/config";
import {useNavigate} from "react-router-dom";
import GlobalContex from "../../../Store/GlobalContext";

const { Title, Text } = Typography;

function ConfirmRegister({ setConfig }: { setConfig: React.SetStateAction<any> }) {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const navigate = useNavigate();
  const { user: { setAuth } } = useContext(GlobalContex);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user-register") as string);
    setUserData(user);
  }, []);

  const finishedRegister = () => {
    message.info('Estamos procesando la información.');
    setLoading(true);
    const dataSend = {
      ...userData,
      role: {
        rolId: 2
      },
      candidate: {
        canCity: userData.canCity,
        canCourse: userData.canCourse,
        canGraduationYear: userData.canGraduationYear,
      }
    }
    delete dataSend?.canCourse;
    delete dataSend?.canCity;
    delete dataSend?.useConfirmPassword;
    delete dataSend?.canGraduationYear;
    postRequest('/api/users', dataSend).then(
      ({ data }) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          sessionStorage.clear();
          onFakeLogin({
            useEmail: dataSend?.useEmail,
            usePassword: dataSend?.usePassword,
          });
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  const onFakeLogin = (values: any) => {
    setLoading(true);
    postRequest('/api/auth/login', values).then(
      ({data}) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          setAuth({...data?.data?.user, usePassword: "******"} || {});
          localStorage.setItem(AUTH_TOKEN, data?.data?.access_token);
          if (data?.data?.user?.role?.rolIdentifier !== "ADMIN_ROLE") {
            navigate('/admin');
          } else {
            navigate('/dashboard');
          }
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  return (
    <Row justify="center">
      <Col span={24} sm={20} md={16} lg={20} xl={18}>
        <Title level={1} className="color-primary pl-20-create">
          Información
        </Title>

        <Divider box={true}/>

        <div className="pl-20-create">
          <Title level={4} className="color-primary">Datos personales</Title>
        </div>
        <ul>
          <li className="color-primary">
            <Text strong className="color-primary">Nombres: </Text> { userData?.useFirstName }
          </li>
          <li className="color-primary">
            <Text strong className="color-primary">Apellido: </Text> { userData?.useFirstSurname }
          </li>
          <li className="color-primary">
            <Text strong className="color-primary">Correo: </Text> { userData?.useEmail }
          </li>
          <li className="color-primary">
            <Text strong className="color-primary">Ciudad: </Text> { userData?.canCity }
          </li>
        </ul>

        <Divider box={true}/>

        <div className="pl-20-create">
          <Title level={4} className="color-primary">Detalles de la cuenta</Title>
        </div>
        <ul>
          <li className="color-primary">
            <Text strong className="color-primary">Usuario: </Text> { userData?.useEmail }
          </li>
          <li className="color-primary">
            <Text strong className="color-primary">Clave: </Text> { userData?.usePassword }
          </li>
        </ul>

        <Space className="pl-20-create py-2">
          <Button shape="round" className="btn-wen" size="large" disabled={loading} onClick={() => setConfig(ConfigPages.register)}>Anterior</Button>
          <Button shape="round" className="btn-dark" size="large" onClick={finishedRegister} loading={loading}>Crear cuenta</Button>
        </Space>

      </Col>
    </Row>
  );
}

export default ConfirmRegister;
