import {Button, Col, Divider, Progress, Row, Typography} from "antd";
import parser from 'html-react-parser';
import GraphLine from "./GraphLine";
import {Link, useNavigate} from "react-router-dom";
import {useContext} from "react";
import GlobalContext from "../../../Store/GlobalContext";
import {Result} from "../../../interfaces/ResultEvaluation.interface";

const { Title, Paragraph } = Typography;

function EvaluationGraph({ data }: { data: Result }) {
  const { profiles, competences } = data;
  const navigate = useNavigate();
  const { career: { showMenu }} = useContext(GlobalContext);

  const goCareer = () => {
    navigate(`/careers`);
  }

  return (
    <Row justify='center'>
      <Col span={24} md={22} lg={21}>
        <Row>
          <Col span={24} className="p-1">
            <Row align="top" gutter={[ 50, 30 ]}>
              <Col span={24} xl={15} >
                {
                  (profiles?.title) && (
                    <>
                      <Title className="color-primary line-height-small" level={5}>{ profiles?.title }</Title>
                      <Divider className='custom-divider' />
                    </>
                  )
                }
                {
                  ( profiles?.data && profiles.data.length > 0 ) && (
                    <Row gutter={[20, 20]}>
                      {
                        profiles?.data?.map((dt, index) => (
                          <Col key={index} span={24} md={8}>
                            <div className="center-center">
                              <Progress trailColor="#DCFFD7" strokeWidth={15} strokeColor="#60F85A" type="circle" percent={parseFloat(dt?.rprScore)} />
                            </div>
                            <div className='my-2'>
                              <Title className="color-primary m-0" level={5}>{ dt?.profile?.proName }</Title>
                              <Divider className='custom-divider m-0' />
                            </div>
                            <Paragraph className="color-primary text-justify line-height-small">
                              { parser(dt?.profile?.proDescription) }
                            </Paragraph>
                          </Col>
                        ))
                      }
                    </Row>
                  )
                }
              </Col>
              <Col span={24} xl={9} >
                <Row gutter={[ 20, 20 ]}>
                  <Col flex='auto'>
                    <Col span={24}>
                      {
                        competences?.map((competence, index) => (
                          <div key={index}>
                            <div>
                              <Title className="color-primary line-height-small" level={5}>{ competence.title }</Title>
                              <Divider className='custom-divider'/>
                            </div>
                            {
                              competence.data.map((value, index1) => (
                                <GraphLine key={index1} data={value} color='bg-secondary' />
                              ))
                            }
                          </div>
                        ))
                      }
                    </Col>
                    {
                      (competences) && (
                        <div>
                          <Button type='primary' shape='round' style={{ marginTop: '1rem' }} onClick={goCareer} disabled={showMenu === false}>Carreras Afines</Button>
                          <p className='my-2 line-height-small'>
                            <small>
                              Para activar en el menú la sección de carreras debes completar los campos de <span><Link to='/profile'><b><u>Tu Perfil.</u></b></Link></span>
                            </small>
                          </p>
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EvaluationGraph;
