import {Button, Col, Row, Space, Typography} from "antd";
import {ItemTest} from "../../../interfaces/Evaluation.interface";
import {TitleInterface} from "./TitleEvaluation";
import {useNavigate} from "react-router-dom";
import ModalIframe from "./ModalIframe";

function ItemEvaluation({ option, title }: { option: ItemTest, title: TitleInterface }) {

  const navigation = useNavigate();

  const goRoute = () => {
    localStorage.setItem('review', JSON.stringify(option));
    navigation('/evaluations');
  }

  const getColor = () => {
    let color = '#0B5580';
    if (title?.key === "completed") {
      if (!option?.testResult) {
        color = title?.color || '';
      }
    }
    if (title?.key === "pending") {
      if (!option?.userTest && option?.tesFree) {
        color = title?.color || '';
      }
    }
    return color;
  }

  return (
    <Row className="p-1" style={{ marginBottom: "5px", backgroundColor: `${ title.bgColor }` }} justify="space-between" align="middle">
      <Col>
        <Space align="center">
          <div className="box" style={{ backgroundColor: `${ title?.boxColor }` }}/>
          <Typography.Text strong style={{ color: `${ getColor() }` }}>{ option?.tesName}</Typography.Text>
        </Space>
      </Col>
      <Col>
        {
          (title?.key === "completed") ?
            <Button shape={"round"} type={ (!option?.testResult) ? 'default' : 'primary' } className={(!option?.testResult) ? 'btn-green' : ''} onClick={goRoute} disabled={!option?.testResult}>
              Revisar
            </Button>
            : <ModalIframe option={option} dataConfig={title} />
        }
      </Col>
    </Row>
  );
}

export default ItemEvaluation;
