const dataCities = [
  'Guayaquil',
  'Quito',
  'Cuenca',
  'Santo Domingo de los Colorados',
  'Machala',
  'Eloy Alfaro',
  'Manta',
  'Portoviejo',
  'Loja',
  'Ambato',
  'Esmeraldas',
  'Quevedo',
  'Riobamba',
  'Milagro',
  'Ibarra',
  'Babahoyo',
  'Sangolquí',
  'Pelileo',
  'Daule',
  'Latacunga',
  'Tulcán',
  'Chone',
  'Pasaje',
  'Santa Rosa',
  'Nueva Loja',
  'Huaquillas',
  'Montecristi',
  'Samborondón',
  'Puerto Francisco de Orellana',
  'Jipijapa',
  'Santa Elena',
  'Otavalo',
  'Cayambe',
  'San Jacinto de Buena Fe',
  'Ventanas',
  'Velasco Ibarra',
  'La Troncal',
  'Salinas',
  'Azogues',
  'Vinces',
  'La Concordia',
  'Rosa Zarate',
  'Balzar',
  'Naranjito',
  'Naranjal',
  'Puyo',
  'Guaranda',
  'La Maná',
  'Tena',
  'San Lorenzo de Esmeraldas',
  'Catamayo',
  'Pedernales',
  'Atuntaqui',
  'Bahía de Caráquez',
  'Puerto Quito',
  'Pedro Carbo',
  'Macas',
  'Yaguachi Nuevo',
  'Valencia',
  'Piñas',
  'San Gabriel',
  'Atacames',
  'Alausí',
  'Lomas de Sargentillo',
  'Muisne',
  'Macará',
  'Baños',
  'San Miguel de Salcedo',
  'Zamora',
  'Puerto Ayora',
  'Valdez',
  'Santa Cruz',
  'Tosagua',
  'Píllaro',
  'Puerto Baquerizo Moreno',
  'Pedro Vicente Maldonado',
  'Archidona',
  'Llacao',
  'Junín',
  'El Chaco',
]

export {
  dataCities
}
