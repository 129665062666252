import { HomeOutlined, UserOutlined, SnippetsOutlined } from "@ant-design/icons";

export const Menu = {
  client: [
    {
      id: "home-page",
      name: "Página principal",
      icon: <HomeOutlined />,
      route: "/admin"
    },
    {
      id: "test",
      name: "Mis evaluaciones",
      icon: <SnippetsOutlined />,
      route: "/evaluations"
    },
    {
      id: "my-profile",
      name: "Mi perfil",
      icon: <UserOutlined />,
      route: "/profile"
    },
  ],
  admin: [
    {
      id: "dashboard",
      name: "Dashboard",
      icon: <HomeOutlined />,
      route: "/dashboard"
    },
  ]
}
