import {ReactNode, useContext, useEffect} from "react";
import {Col, Modal, Row} from "antd";
import HeaderLayout from "./components/HeaderLayout";
import FooterLayout from "./components/FooterLayout";
import {useLocation, useNavigate} from "react-router-dom";
import GlobalContext from "../Store/GlobalContext";

function LayoutPrivate({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const {
    navBar: { setNav },
    user: { auth },
    test: { open, setOpen },
    option: { currentOption }
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setNav(pathname);
  }, [pathname]);

  useEffect(() => {
    if (!auth?.useId) {
      navigate('/');
    }
  }, [auth]);
  return (
    <main>
      <HeaderLayout />
      <Row className="bg-gray min-body-content" justify="center" style={{ paddingBottom: '80px' }}>
        <Col span={23} md={22} lg={20} xxl={18} className="bg-white">
          { children }
        </Col>
      </Row>
      <Modal
        key={currentOption?.tesId}
        title={ currentOption.tesName }
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <div>
            <p className='m-0 text-center'>
              power by centraltest
            </p>
          </div>
        }
        width={1800}
      >
        <Row>
          <Col span={24}>
            <iframe src={`${currentOption?.userTest?.uteLink}`} className='w-100 min-vh-90'/>
          </Col>
        </Row>
      </Modal>
      <FooterLayout />
    </main>
  );
}

export default LayoutPrivate;
