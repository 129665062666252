import axios from 'axios';
import {notification} from 'antd';
import {API_BASE_URL, AUTH_TOKEN, REFRESH_TOKEN} from "./config";

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';

// API Request interceptor
service.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${ jwtToken }`;
    }

    if (config?.formData) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        if (!jwtToken) {
            config.headers['Authorization'] = 'Basic ' + btoa("clave");
        }
    }else{
        config.headers['Accept'] = 'application/json';
        config.headers['Content-Type'] = 'application/json';
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API respone interceptor
let isRefreshing = false;
let refreshSubscribers = [];
service.interceptors.response.use( (response) => {
    return response
}, async (error) => {


    const { config, response: { status, data } } = error;
    const originalRequest = config;

    // Remove token and redirect
    if (status === 4010) {
        if (!isRefreshing) {
            isRefreshing = true;
            const refresh_token = localStorage.getItem(REFRESH_TOKEN);
            if (refresh_token === "undefined" || refresh_token === undefined || refresh_token === null || refresh_token === '') {
                localStorage.clear();
                window.location = '/';
                return Promise.reject(data);
            }
            fetch(`${ API_BASE_URL }/security/oauth/token`, {
                method: 'POST',
                body: 'grant_type=refresh_token&refresh_token=' + refresh_token,
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic ' + btoa("calve")
                }
            }).then(res => res.json()).then((data) => {
                isRefreshing = false;
                if (data) {
                    if (data?.access_token && data?.refresh_token) {
                        localStorage.setItem(AUTH_TOKEN, data.access_token);
                        localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
                        // authenticated(data.access_token);
                    } else {
                        localStorage.clear();
                        window.location = '/';
                        return Promise.reject(data);
                    }
                }
                isRefreshing = false;
                const {access_token} = data;
                onRrefreshed(access_token);
            }).catch(e=>{
                localStorage.clear();
                window.location = '/';
                return Promise.reject(data);
            });
        }

        return new Promise((resolve, reject) => {
            subscribeTokenRefresh(accessToken => {
                originalRequest.headers['authorization'] = 'Bearer ' + accessToken;
                resolve(axios(originalRequest));
            });
        });
    } else {
        return Promise.reject(data);
    }
});

const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
}

const  onRrefreshed = (accessToken) => {
    refreshSubscribers.map(cb => cb(accessToken));
}

export default service
