import React, {useState} from "react";
import {Button, Col, Form, Input, message, Row, Space, Typography} from "antd";
import Divider from "./Divider";

import {ConfigPages} from "../../Public/config.pages";
import {postRequest} from "../../../config/ApiService";

const { Text, Paragraph } = Typography;
const { Item } = Form;

function RecoveryForm({ setConfig }: { setConfig: React.SetStateAction<any> }) {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    postRequest(`/api/password_resets/generate_token?useEmail=${values.useEmail}`, {}, 'get').then(
      ({data}) => {
        setLoading(false);
        if (data?.success) {
          message.success(data?.message);
          setConfig(ConfigPages.login)
        } else {
          message.error(data?.message || "Error en la solicitud.");
        }
      }
    ).catch(reason => {
      setLoading(false);
      message.error(reason?.message || "Error inesperado en la solicitud.");
    })
  }

  return (
    <Row justify="center">
      <Col span={24} sm={20} md={16} lg={20} xl={18}>
        <Text className="color-primary line-height-small" style={{ fontSize: "2rem" }}>
          <b>Recuperar</b> cuenta
        </Text>
        <Form form={form} onFinish={onFinish} autoComplete="off" className='mt-1'>
          <Item
            name="useEmail"
            rules={[{ required: true, message: 'Por favor, ingrese un correo electrónico.' }]}
          >
            <Input size="large" placeholder="Correo electrónico" />
          </Item>
          <Space direction="vertical" className='text-center w-100'>
            <Button shape='round' type='default' size="large" className="btn-dark" htmlType="submit" loading={loading}>
              Enviar link
            </Button>
            <Paragraph className="color-primary line-height-small">
              Al continuar, se te enviará un link donde podrás recuperar tu contraseña.
            </Paragraph>
          </Space>
        </Form>
      </Col>
      <Col span={24} sm={20} md={18} lg={22} xl={20} className="text-center pt-1">
        <Divider box={false} colorOne="bg-primary-wen" />
        <Space direction="vertical">
          <Text className="color-primary">¿Ya tienes cuenta en ECO?</Text>
          <Button type="default" className="btn-wen" shape="round" onClick={() => setConfig(ConfigPages.login)}>
            Iniciar sesión
          </Button>
        </Space>
      </Col>
    </Row>
  );
}

export default RecoveryForm;
