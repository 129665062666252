import {useEffect, useState} from "react";
import {AuthInterface} from "../interfaces/Auth.interface";
import {USER_INFO} from "../config/config";

function Auth() {
  const [auth, setAuth] = useState<AuthInterface | {}>();

  useEffect(() => {
    if (localStorage.getItem(USER_INFO)) {
      const user = JSON.parse(localStorage.getItem(USER_INFO) as string);
      setAuth(user || {});
    }
  }, []);

  useEffect(() => {
    if (auth === undefined) {
      return;
    }
    // @ts-ignore
    if (auth?.useId) {
      localStorage.setItem(USER_INFO, JSON.stringify(auth));
    }
  }, [auth]);

  return { auth, setAuth};
}

export default Auth;
